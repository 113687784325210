import axios from "axios";
import { Product } from "../types/Product";
import { Order } from "../types/Order";
import { API_URL } from "../config/ApiCredentials";

export default class OrderService {
    createOrder({
        products,
        email,
        first_name,
        last_name,
        promo_code="",
        currency="RUB",
        phone_number,
        user_id="",
        delivery_type,
        delivery_address="",
        _callback,
        _onStockError=()=>{}
    }: {
        products: Array<Product>,
        email: string,
        first_name: string,
        last_name: string,
        promo_code?: string,
        currency?: "USD" | "RUB",
        phone_number: string,
        user_id?: string,
        delivery_type: "pickup" | "courier" | "courier_moscow",
        delivery_address?: string,
        _callback: (o: Order) => void,
        _onStockError?: () => void
    }) {
        axios.post(
            `${API_URL}/orders/create`,
            {order:{
                products: products,
                email: email,
                first_name: first_name,
                last_name: last_name,
                promo_code: promo_code,
                currency: currency,
                phone_number: phone_number,
                user_id: user_id,
                delivery_type: delivery_type,
                delivery_address: delivery_address
            }},
            { withCredentials: true, headers: { 'Shop-Id': "REMINI" } }
        ).then(r => {
            _callback(r.data);
        }).catch(e => {
            console.log(e);
            if(e.status == 409) {
                _onStockError();
            }
        });
    }

    getOrder({
        id,
        _callback
    }: {
        id: string,
        _callback: (o: Order) => void
    }) {
        axios.get(
            `${API_URL}/order?id=${id}`,
            { withCredentials: true, headers: { 'Shop-Id': "REMINI" } }
        ).then(r => {
            _callback(r.data);
        }).catch(e => {
            console.log(e);
        });
    }

    expectPaymentForOrder({
        id,
        _callback
    }: {
        id: string,
        _callback: (o: Order) => void
    }) {
        axios.get(
            `${API_URL}/order-payment-expect?id=${id}`
        ).then(r => {
            _callback(r.data);
        }).catch(e => {
            console.log(e);
        });
    }
}
