import React, { useEffect, useState } from 'react';

// types
import { Product } from '../types/Product';

// styles
import "./Catalog.scss";
import { Link } from 'react-router-dom';

export default function Catalog({
    products,
    viewMode
}: {
    products: Array<Product>,
    viewMode: "man" | "item"
}) {
    // const [viewMode, setViewMode] = useState<string>("man");

    return(
        <div className='Catalog__root'>
            {products.map((item, index) => {
                return(
                    <ProductView
                        key={index}
                        product={item}
                        viewMode={viewMode}
                    />
                );
            })}
            {/*<div className='view_selector' onClick={()=>setViewMode(viewMode=="item"?"man":"item")}>
                {viewMode == "man" ? 
                <img src="/t-shirt.png" />
                :
                <img src="/man.png" />
                }
            </div>*/}
            {/*<div className='car_link' style={viewMode=="man"?{display:'none'}:{}} onClick={()=>window.location.replace('/game')}>
                <img src="/car.png" />
            </div>*/}
        </div>
    );
}

export function ProductView({
    product,
    viewMode="item"
}: {
    product: Product,
    viewMode: string
}) {
    const [isAvailable, setAvailability] = useState<boolean>(false);
    const [availableSizes, setAvailableSizes] = useState<string>("");
    useEffect(() => {
        let av: boolean = false;
        let as: string = "";
        for(const stockitem of product.availability) {
            if(stockitem.amount > 0) {
                av = true;
                as += stockitem.size + " / ";
            }
        }

        if(as.length > 0) {
            as = as.slice(0, as.length-2);
        }

        setAvailability(av);
        setAvailableSizes(as);

        let interval = setInterval(() => rotateImage(), 600);

        return () => clearInterval(interval);
    }, [product]);

    function rotateImage() {
        // @ts-ignore
        /*const currentTransform = document.getElementById(`img1-${product._id}`).style.transform;
        console.log(`current transform: ${currentTransform}`);
        if(currentTransform == "") {
            document.getElementById(`img1-${product._id}`)!.style.transform = `rotate(45deg)`;
        } else {
            let degrees = Number(currentTransform.split("(")[1].split("deg")[0]);
            degrees += 45;
            console.log(degrees);
            if(degrees == 360) {
                degrees = 0;
            }
            document.getElementById(`img1-${product._id}`)!.style.transform = `rotate(${degrees}deg)`;
        }*/
        /*let degrees = Number(currentTransform.split("(")[1].split("deg")[0]);
        degrees += 45;
        if(degrees == 360) {
            degrees = 0;
        }*/
        // @ts-ignore
        // document.getElementById(`img1-${product._id}`).style.transform = `rotate(${degrees}deg)`;
    }

    return(
        <Link to={`/product/${product._id}`} className="Product__root">
            <div className="image_container" style={viewMode=="man"?{aspectRatio:0.5}:{}}>
                <img id={`img1-${product._id}`} className="img1" style={viewMode=="man"?{filter: "brightness(1.1)"}:{}} src={viewMode == "item" ? product.images[0].min : product.images[2].min} alt="image1" />
                <img className="img2" style={(viewMode=="man" && product.images.length < 4)?{objectFit: 'contain'}:{}} src={viewMode == "item" ? (product.images.length > 1 ? product.images[1].min : product.images[0].min) : (product.images.length > 3 ? product.images[3].min : product.images[0].min)} alt="image2" />
            </div>
            <p className='name'>{product.name}</p>
            {isAvailable?
            <p className='price'>{Math.round(product.availability[0].prices[0].value)} {product.availability[0].prices[0].currency}</p>
            :
            <p className="out_of_stock">SOLD OUT</p>    
            }

            {availableSizes != "" &&
            <p className="sizes">{availableSizes}</p>
            }
        </Link>
    );
}

function formatPrice(prc: number) {

}