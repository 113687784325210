import React from 'react';

// styles
import "./Footer.scss";
import { Link } from 'react-router-dom';

export default function Footer() {
    return(
        <div className='Footer__root'>
            <Link to="/" className="logo">REMINISCE (c) 2024</Link>
            <div className="horizontal">
                {/*<Link className='link' to="/contacts">КОНТАКТЫ</Link>*/}
                <Link className='link' to="/politics">КОНФИДЕНЦИАЛЬНОСТЬ</Link>
                <Link className='link' to="/information">ОФЕРТА</Link>
            </div>
            <a href="https://t.me/ivangalki" target='_blank' className="credentials">designed & built by <span>ivan galkin</span></a>
        </div>
    );
}
