import React from 'react';
import Footer from '../components/Footer';

import "../styles/Main.scss";

import CollectionBanner from '../components/CollectionBanner';

export default function MainPage() {
    return(
        <div className="Main__root">
            <div className="banners">
                <CollectionBanner
                    name="SHOP DROP II" // shop
                    image="/drop2_banner1.jpg"
                    id="dropii"
                    link="/catalog"
                />

                {/*<CollectionBanner
                    name="SHOP DROP II" // shop
                    image="/drop2_banner2.jpg"
                    id="dropii"
                />*/}

                {/*<CollectionBanner
                    name="SHOP DROP I" // shop
                    image="/banner1.jpg"
                    id="dropi"
                    link="/catalog"
                />*/}

                {/*<CollectionBanner
                    name="FW24"
                    image=""
                    id="fw24"
                >*/}
            </div>

            <Footer/>
        </div>
    );
}
