import React, { useState, useEffect } from 'react';


// components
import Footer from '../components/Footer';
import Catalog from '../components/Catalog';

// styles
import "../styles/Collection.scss";

// types
import { Product } from '../types/Product';

// services
import CatalogService from '../services/CatalogService';
import { Link } from 'react-router-dom';

export default function CollectionPage() {
    const [products, setProducts] = useState<Array<Product>>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [collectionID, setCollectionID] = useState<string>("");
    const [collectionData, setCollectionData] = useState({
        name: "",
        description: ""
    });
    const catalogService = new CatalogService();

    useEffect(() => {
        let patharr = window.location.pathname.split("/");
        let cid = patharr[patharr.length - 1];
        if(cid == "") {
            cid = patharr[patharr.length - 2];
        }

        setCollectionID(cid);
    }, []);

    useEffect(() => {
        if(collectionID != "") {
            catalogService.getProducts({
                _callback: r => {
                    let _r: Array<Product> = [];
                    if(collectionID == "dropi") {
                        for(const p of r) { 
                            if(Number(p._id) < 9) {
                                _r.push(p);
                            }
                        }
    
                        setProducts(_r);
                        setCollectionData({
                            name: "DROP I",
                            description: ""
                        });
                    } else if(collectionID == "dropii") {
                        for(const p of r) { 
                            if(Number(p._id) >= 9) {
                                _r.push(p);
                            }
                        }
    
                        setProducts(_r);
                        setCollectionData({
                            name: "DROP II",
                            description: ""
                        });
                    } else {
                        setProducts(r);
                    }
                }
            });
        }
    }, [collectionID]);

    return(
        <div className="Coll__root">
            <div className="catalog_wrapper">
                {!loading ?
                <Catalog
                    products={products}
                    viewMode={"man"}
                />
                :
                <div/>
                }
            </div>

            <Footer/>
        </div>
    );
}