import React from 'react';
import Footer from '../components/Footer';

// styles
import "../styles/Contacts.scss";

export default function ContactsPage() {
    return(
        <div className="Contacts__root">
            <div className="wrapper">
                <p className="title">Контакты</p>
                <p className="content">
                    ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ РЕМИНИС<br/>
                    ОГРН 1237700476705<br/>
                    Юридический адрес: г. Москва, Хлебозаводский пр., д. 7, стр. 9, помещ. 24/Н<br/>
                    <br/>
                    {/*Реклама: <a href="mailto:adv@reminisce.moscow">adv@reminisce.moscow</a><br/>
                    СМИ: <a href="mailto:media@reminisce.moscow">media@reminisce.moscow</a><br/>
                    Продажи: <a href="mailto:sales@reminisce.moscow">sales@reminisce.moscow</a><br/>
    По общим вопросам: <a href="mailto:office@reminisce.moscow">office@reminisce.moscow</a><br/>*/}
                    E-mail: <a href="mailto:reminiscemoscow@gmail.com">reminiscemoscow@gmail.com</a><br/>
                    <br/>
                    Telegram: <a href="https://t.me/reminisce_moscow" target='_blank'>@reminisce_moscow</a>
                    <br/><br/>
                    Самовывоз производится по адресу: <a href="https://yandex.ru/maps/-/C2Hxfma" target='_blank'>Нижний Сусальный пер., 5с17 (SPIN4SPIN)</a>
                </p>
            </div>

            <Footer/>
        </div>
    );
}