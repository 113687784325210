import axios from "axios";
import { Product } from "../types/Product";
import { API_URL } from "../config/ApiCredentials";
import { CartProduct } from "../types/CartProduct";

const TEMPLATE_PRODUCTS = [
    {
        _id: "0001",
        name: "Opium Hoodie",
        description: "ПУЛОВЕР С КАПЮШОНОМ ИЗ СВЕРХПЛОТНОГО ВЫСТИРАННОГО ХЛОПКА. BOXY-FIT СО СПУЩЕННЫМ ПЛЕЧОМ. ДВУХСЛОЙНЫЙ КАПЮШОН. КЕНГУРУ КАРМАН. СДЕЛАНО В РОССИИ.",
        availability: [
            {
                size: "S",
                amount: 10,
                prices: [
                    {
                        currency: "RUB",
                        value: 1800000,
                        newValue: 0
                    }
                ]
            }
        ],
        composition: [{
            alias: "ХЛОПОК",
            value: "100%"
        }],
        measurements: [],
        other_data: {},
        condition: "new",
        created_at: "",
        brand: [],
        categories: [{
            name: "hoodies",
            id: "0"
        }],
        images: [{
            id: "",
            min: "/media/0001_1.jpg",
            max: "/media/0001_1.jpg"
        },
        {
            id: "",
            min: "/media/0001_2.jpg",
            max: "/media/0001_2.jpg"
        },
        {
            id: "",
            min: "/media/0001_3.jpg",
            max: "/media/0001_3.jpg"
        }]
    },
    {
        _id: "0002",
        name: "Opium T-Shirt",
        description: "ФУТБОЛКА ИЗ СВЕРХПЛОТНОГО ВЫСТИРАННОГО ХЛОПКА. BOXY-FIT СО СПУЩЕННЫМ ПЛЕЧОМ. СДЕЛАНО В РОССИИ.",
        availability: [
            {
                size: "S",
                amount: 8,
                prices: [
                    {
                        currency: "RUB",
                        value: 1000000,
                        newValue: 800000
                    }
                ]
            },
            {
                size: "M",
                amount: 8,
                prices: [
                    {
                        currency: "RUB",
                        value: 1000000,
                        newValue: 0
                    }
                ]
            }
        ],
        composition: [{
            alias: "ХЛОПОК",
            value: "100%"
        }],
        measurements: [],
        other_data: {},
        condition: "new",
        created_at: "",
        brand: [],
        categories: [{
            name: "t-shirts",
            id: "0"
        }],
        images: [{
            id: "",
            min: "/media/0002_1.jpg",
            max: "/media/0002_1.jpg"
        },
        {
            id: "",
            min: "/media/0002_2.jpg",
            max: "/media/0002_2.jpg"
        },
        {
            id: "",
            min: "/media/0002_3.jpg",
            max: "/media/0002_3.jpg"
        }]
    },
    {
        _id: "0003",
        name: "Le Sommeil Sweatshirt",
        description: "ПУЛОВЕР ИЗ СВЕРХПЛОТНОГО ВЫСТИРАННОГО ХЛОПКА. BOXY-FIT СО СПУЩЕННЫМ ПЛЕЧОМ. СДЕЛАНО В РОССИИ.",
        availability: [
            {
                size: "M",
                amount: 8,
                prices: [
                    {
                        currency: "RUB",
                        value: 1800000,
                        newValue: 0
                    }
                ]
            },
            {
                size: "L",
                amount: 8,
                prices: [
                    {
                        currency: "RUB",
                        value: 1800000,
                        newValue: 1600000
                    }
                ]
            }
        ],
        composition: [{
            alias: "ХЛОПОК",
            value: "100%"
        }],
        measurements: [],
        other_data: {},
        condition: "new",
        created_at: "",
        brand: [],
        categories: [{
            name: "t-shirts",
            id: "0"
        }],
        images: [{
            id: "",
            min: "/media/0003_1.jpg",
            max: "/media/0003_1.jpg"
        },
        {
            id: "",
            min: "/media/0003_2.jpg",
            max: "/media/0003_2.jpg"
        },
        {
            id: "",
            min: "/media/0003_3.jpg",
            max: "/media/0003_3.jpg"
        }]
    },
    {
        _id: "0004",
        name: "Soviet T-Shirt",
        description: "ФУТБОЛКА ИЗ СВЕРХПЛОТНОГО ВЫСТИРАННОГО ХЛОПКА. BOXY-FIT СО СПУЩЕННЫМ ПЛЕЧОМ. СДЕЛАНО В РОССИИ.",
        availability: [
            {
                size: "M",
                amount: 8,
                prices: [
                    {
                        currency: "RUB",
                        value: 1000000,
                        newValue: 0
                    }
                ]
            },
            {
                size: "L",
                amount: 1,
                prices: [
                    {
                        currency: "RUB",
                        value: 1800000,
                        newValue: 0
                    }
                ]
            },
            {
                size: "XL",
                amount: 4,
                prices: [
                    {
                        currency: "RUB",
                        value: 1800000,
                        newValue: 0
                    }
                ]
            }
        ],
        composition: [{
            alias: "ХЛОПОК",
            value: "100%"
        }],
        measurements: [],
        other_data: {},
        condition: "new",
        created_at: "",
        brand: [],
        categories: [{
            name: "sweatshirts",
            id: "0"
        }],
        images: [{
            id: "",
            min: "/media/0004_1.jpg",
            max: "/media/0004_1.jpg"
        },
        {
            id: "",
            min: "/media/0004_2.jpg",
            max: "/media/0004_2.jpg"
        },
        {
            id: "",
            min: "/media/0004_3.jpg",
            max: "/media/0004_3.jpg"
        }]
    },
    {
        _id: "0005",
        name: "Reminisce Hoodie",
        description: "ПУЛОВЕР С КАПЮШОНОМ ИЗ СВЕРХПЛОТНОГО ВЫСТИРАННОГО ХЛОПКА. BOXY-FIT СО СПУЩЕННЫМ ПЛЕЧОМ. ДВУХСЛОЙНЫЙ КАПЮШОН. КЕНГУРУ КАРМАН. СДЕЛАНО В РОССИИ.",
        availability: [
            {
                size: "M",
                amount: 0,
                prices: [
                    {
                        currency: "RUB",
                        value: 1000000,
                        newValue: 0
                    }
                ]
            },
            {
                size: "L",
                amount: 3,
                prices: [
                    {
                        currency: "RUB",
                        value: 1000000,
                        newValue: 0
                    }
                ]
            }
        ],
        composition: [{
            alias: "ХЛОПОК",
            value: "100%"
        }],
        measurements: [],
        other_data: {},
        condition: "new",
        created_at: "",
        brand: [],
        categories: [{
            name: "t-shirts",
            id: "0"
        }],
        images: [{
            id: "",
            min: "/media/0005_1.jpg",
            max: "/media/0005_1.jpg"
        },
        {
            id: "",
            min: "/media/0005_2.jpg",
            max: "/media/0005_2.jpg"
        },
        {
            id: "",
            min: "/media/0005_3.jpg",
            max: "/media/0005_3.jpg"
        }]
    },
    {
        _id: "0006",
        name: "Flowers T-Shirt",
        description: "ПУЛОВЕР С КАПЮШОНОМ ИЗ СВЕРХПЛОТНОГО ВЫСТИРАННОГО ХЛОПКА. BOXY-FIT СО СПУЩЕННЫМ ПЛЕЧОМ. ДВУХСЛОЙНЫЙ КАПЮШОН. КЕНГУРУ КАРМАН. СДЕЛАНО В РОССИИ.",
        availability: [
            {
                size: "M",
                amount: 0,
                prices: [
                    {
                        currency: "RUB",
                        value: 1000000,
                        newValue: 0
                    }
                ]
            },
            {
                size: "L",
                amount: 3,
                prices: [
                    {
                        currency: "RUB",
                        value: 1000000,
                        newValue: 0
                    }
                ]
            }
        ],
        composition: [{
            alias: "ХЛОПОК",
            value: "100%"
        }],
        measurements: [],
        other_data: {},
        condition: "new",
        created_at: "",
        brand: [],
        categories: [{
            name: "t-shirts",
            id: "0"
        }],
        images: [{
            id: "",
            min: "/media/0006_1.jpg",
            max: "/media/0006_1.jpg"
        },
        {
            id: "",
            min: "/media/0006_2.jpg",
            max: "/media/0006_2.jpg"
        },
        {
            id: "",
            min: "/media/0006_3.jpg",
            max: "/media/0006_3.jpg"
        }]
    },
    {
        _id: "0007",
        name: "Reminisce T-Shirt",
        description: "ПУЛОВЕР С КАПЮШОНОМ ИЗ СВЕРХПЛОТНОГО ВЫСТИРАННОГО ХЛОПКА. BOXY-FIT СО СПУЩЕННЫМ ПЛЕЧОМ. ДВУХСЛОЙНЫЙ КАПЮШОН. КЕНГУРУ КАРМАН. СДЕЛАНО В РОССИИ.",
        availability: [
            {
                size: "M",
                amount: 0,
                prices: [
                    {
                        currency: "RUB",
                        value: 1000000,
                        newValue: 0
                    }
                ]
            },
            {
                size: "L",
                amount: 3,
                prices: [
                    {
                        currency: "RUB",
                        value: 1000000,
                        newValue: 0
                    }
                ]
            }
        ],
        composition: [{
            alias: "ХЛОПОК",
            value: "100%"
        }],
        measurements: [],
        other_data: {},
        condition: "new",
        created_at: "",
        brand: [],
        categories: [{
            name: "t-shirts",
            id: "0"
        }],
        images: [{
            id: "",
            min: "/media/0007_1.jpg",
            max: "/media/0007_1.jpg"
        },
        {
            id: "",
            min: "/media/0007_2.jpg",
            max: "/media/0007_2.jpg"
        },
        {
            id: "",
            min: "/media/0007_3.jpg",
            max: "/media/0007_3.jpg"
        }]
    }
];

export default class CatalogService {
    getProducts({
        _callback
    }: {
        _callback: (r: Array<Product>) => void
    }) {
        //_callback(TEMPLATE_PRODUCTS);
        //return;

        axios.get(
            `${API_URL}/catalog`,
            { withCredentials: true, headers: { 'Shop-Id': "REMINI" } }
        ).then(r => {
            _callback(r.data.data);
        }).catch(e => {
            _callback([]);
        });
    }

    getProduct({
        id,
        _callback
    }: {
        id: string,
        _callback: (r: Product) => void
    }) {
        //let r: Product = TEMPLATE_PRODUCTS[0];
        /*for(const p of TEMPLATE_PRODUCTS) {
            if(p._id == id) {
                r = p;
            }
        }*/

        axios.get(
            `${API_URL}/product?id=${id}`,
            { withCredentials: true, headers: { 'Shop-Id': "REMINI" } }
        ).then(r => {
            _callback(r.data.product);
        }).catch(e => {

        });
    }

    getCartItems({
        cart,
        _callback
    }: {
        cart: Array<CartProduct>,
        _callback: (c: Array<Product>) => void
    }) {
        axios.post(
            `${API_URL}/cart-items`,
            { cart: cart },
            { withCredentials: true, headers: { 'Shop-Id': "REMINI" } }
        ).then(r => {
            _callback(r.data);
        }).catch(e => {
            console.log(e);
            _callback([]);
        });
    }
}
