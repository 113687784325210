import React, { useEffect, useState } from 'react';

// styles
import "./NavigationBar.scss";
import { Link, useNavigation } from 'react-router-dom';
import { CartProduct } from '../types/CartProduct';

export default function NavigationBar({ cart }: { cart: Array<CartProduct> }) {
    const [mobileMenuState, setMobileMenuState] = useState<boolean>(false);
    
    useEffect(() => {
        setMobileMenuState(false);
    }, [window.location.pathname]);

    return(
        <>
            <div className='Nav__root'>
                <Link to="/" className="logo"><img style={{objectFit:'contain',height:'20px'}} src="/reminisce.png"/></Link>
                <div className='navigation'>
                    {/*<Link to="/" className='item'>КОЛЛЕКЦИИ</Link>*/}
                    <Link to="/catalog" className='item'>КАТАЛОГ</Link>
                    <Link to="/gallery" className='item'>ГАЛЕРЕЯ</Link>
                    <Link to="/contacts" className='item'>КОНТАКТЫ</Link>
                    {/*<Link to="/about" className='item'>О БРЕНДЕ</Link>*/}
                </div>

                <div className='profile'>
                    {/*<Link to="/profile" className='item'>ПРОФИЛЬ</Link>*/}
                    <Link to="/cart" className='item'>КОРЗИНА({cart.length})</Link>
                </div>

                <div className='menu' onClick={() => setMobileMenuState(!mobileMenuState)}>
                    <div/>
                    <div/>
                    <div/>
                </div>

                <Link to="/cart" className='cart_link'>
                    <img src="/shopping-cart.png"/>
                </Link>
            </div>

            {mobileMenuState &&
            <div className='Menu__root'>
                {/*<Link to="/collections" className='item'>КОЛЛЕКЦИИ</Link>*/}
                <Link to="/catalog" onClick={()=>setMobileMenuState(false)} className='item'>КАТАЛОГ</Link>
                <Link to="/gallery" onClick={()=>setMobileMenuState(false)} className='item'>ГАЛЕРЕЯ</Link>
                <Link to="/contacts" onClick={()=>setMobileMenuState(false)} className='item'>КОНТАКТЫ</Link>
                {/*<Link to="/about" className='item'>О БРЕНДЕ</Link>*/}

                {/*<Link to="/profile" className='item with_spacing'>ПРОФИЛЬ</Link>*/}
                <Link to="/cart" onClick={()=>setMobileMenuState(false)} className='item'>КОРЗИНА({cart.length})</Link>
            </div>
            }
        </>
    );
}