import React, { useEffect, useState } from 'react'; // @ts-ignore
import OrderService from '../services/OrderService.ts';
import { Order } from '../types/Order.js';
import "../styles/Checkout.scss"; // @ts-ignore
import { Helmet } from 'react-helmet'; // @ts-ignore
import { API_URL } from '../config/ApiCredentials.ts';

const orderService = new OrderService();

export default function CheckoutPage() { // @ts-ignore
    const [order, setOrder] = useState<Order>({
        total: 0,
        currency: "RUB",
        _id: "",
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        delivery_address: ""
    });

    const [now, setNow] = useState<Date>(new Date());

    useEffect(() => {
        let patharr = window.location.pathname.split("/");
        let orderid = patharr[patharr.length-1];
    
        orderService.getOrder({
            id: orderid,
            _callback: (r: Order) => {
                setOrder(r);

                if(r.status != "opened") {
                    window.location.replace(`/order-tracking/${r._id}`);
                }
            }
        });

        var intervalId = setInterval(function() {
            let now = new Date();
            setNow(now);
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    function onSuccess() {
        orderService.expectPaymentForOrder({
            id: order._id,
            _callback: (o: Order) => {
                if(o.expect_payment) {
                    window.location.replace(`/order-tracking/${o._id}`);
                }
            }
        });
    }

    if(order == null) {return <div/>}
    if((900000-now.getTime()+(new Date(order.created_at)).getTime()) < 0) {
        return <div/>;
    }

    return(
        <div className='Checkout__root'>
            <Helmet>
                <title>Оформление</title>
                <script type="text/javascript">{`
            function pay() {
                var widget = new cp.CloudPayments();
                    widget.pay('charge', // или 'charge'
                        { //options
                            publicId: 'pk_764e5a7aaf6416bdb05e159904ae8', //id из личного кабинета
                            description: 'Заказ: REMINISCE', //назначение 
                            amount: ${order.total}, //сумма
                            currency: '${order.currency}', //валюта
                            accountId: '${order.email}', //идентификатор плательщика (необязательно)
                            invoiceId: '${order._id}', //номер заказа  (необязательно)
                            email: '${order.email}', //email плательщика (необязательно)
                            skin: "mini", //дизайн виджета (необязательно)
                            autoClose: 0, //время в секундах до авто-закрытия виджета (необязательный)
                            data: {
                                myProp: 'myProp value'
                            },
                            configuration: {
                                common: {
                                    successRedirectUrl: "https://reminisce.moscow/payment/success", // адреса для перенаправления 
                                    failRedirectUrl: "https://reminisce.moscow/payment/fail"        // при оплате по Tinkoff Pay
                                }
                            },
                            payer: { 
                                firstName: '${order.first_name}',
                                lastName: '${order.last_name}',
                                middleName: '',
                                
                                address: '${order.delivery_address}',
                                country: 'RU',
                                phone: '${order.phone_number}'
                            }
                        },
                        {
                            onSuccess: function (options) { // success
                                    var xhr = new XMLHttpRequest();
                                    xhr.open('GET', '${API_URL}/order-payment-expect?id=${order._id}', true);
                                    xhr.setRequestHeader('Shop-Id', "REMINI");
                                    xhr.onreadystatechange = function() {
                                        // If the request completed successfully, handle the response
                                        if (xhr.readyState == 4 && xhr.status == 200) {
                                            var response = JSON.parse(xhr.responseText);
                                            console.log(response);
                                            document.cookie='cart=[];max-age=604800;path=/';
                                            window.location.replace('/order-tracking/${order._id}');
                                            _tmr.push({ type: 'reachGoal', id: 3384321, goal: 'Purchase'});
                                        }
                                    };
                                    xhr.send();
                            },
                            onFail: function (reason, options) { // fail
                                //действие при неуспешной оплате
                            },
                            onComplete: function (paymentResult, options) { //Вызывается как только виджет получает от api.cloudpayments ответ с результатом транзакции.
                                //например вызов вашей аналитики
                            }
                        }
                    )
                };

                document.querySelector('#checkout').onclick = pay;
            `}</script>
            </Helmet>
            
            <div className='wrapper'>
                <p className='title'>Оплата заказа #{order._id}</p>
                {(900000-now.getTime()+(new Date(order.created_at)).getTime()) > 0 ?
                <p className='total'>
                    К оплате: {order.total} {order.currency}<br/>
                    Оплатите заказ в течение {Math.floor((900000-now.getTime()+(new Date(order.created_at)).getTime())/60000)<10?"0":""}{Math.floor((900000-now.getTime()+(new Date(order.created_at)).getTime())/60000)}:{Math.round((900000-now.getTime()+(new Date(order.created_at)).getTime())/1000)%60<10?"0":""}{Math.round((900000-now.getTime()+(new Date(order.created_at)).getTime())/1000)%60}
                </p>
                :
                <p className='total'>
                    Заказ просрочен.
                </p>
                }
                <div id="checkout">
                    <p>Оплатить</p>
                </div>
            </div>
        </div>
    );
}