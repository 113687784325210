import { useEffect, useState } from "react";
import "../styles/Security.scss";

const igors = [1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1];

export default function SecurityPage() {
    const [wide, setWide] = useState(false);
    useEffect(() => {
        const timer = setInterval(() => {
            let igs = document.getElementsByClassName("igor");
            // @ts-ignore
            for(const ig of igs) {
                if(ig.style.transform == 'scaleX(2)') {
                    ig.style.transform = 'scaleX(1.5)';
                } else {
                    ig.style.transform = 'scaleX(2)';
                }
            }
        }, 220);
    }, []);

    return(
        <div className="Security__root">
            {igors.map((_igor, _index) => {
                return(
                    <>
                    {igors.map((igor, index) => {
                console.log(index);
                return(
                    <div 
                        className="igor_c"
                        style={{
                            right: `${-20+index*80}px`,
                            top: `${_index}0vh`
                        }}
                    >
                        <img className="igor" src={`/IGOR.png`} />
                    </div>
                );
            })}
                    </>
                );
            })}
            <p>ЛУГАНЬСКА НАРОДНА РЕСПУБЛIКА</p>
        </div>
    );
}
