import React from "react";
import "./Selector.scss";

export default function Selector({
    title,
    items,
    value,
    onChange=()=>{}
}: {
    title: string,
    items: Array<string>,
    value: string,
    onChange?: (r: string) => void
}) {
    return(
        <div className="Selector__container">
            <p className="sl_title">{title}</p>
            {items.map((item, index) => {
                return(
                    <div className="item" key={index} onClick={()=>onChange(item)}>
                        <div className="checkbox">
                            {value==item&&<p>x</p>}
                        </div>
                        <p className="item_title">{item}</p>
                    </div>
                );
            })}
        </div>
    );
}