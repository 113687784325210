import React from "react";
import "../styles/Gallery.scss";
import Footer from "../components/Footer";

export default function GalleryPage() {
    return(
        <div className="Gallery__root">
            {/* new images */}
            <div className="double">
                <img src="/gallery/911.jpg"/>
                <img src="/gallery/medieval.jpg"/>
            </div>
            <div className="double">
                <img src="/gallery/wronglane.jpg"/>
                <img src="/gallery/na2.jpg"/>
            </div>
            <div className="single_tall">
                <img src="/gallery/collage2.jpg"/>
            </div>
            <div className="double">
                <img src="/gallery/pola_basic.jpg"/>
                <img src="/gallery/pola_na.jpg"/>
            </div>
            <div className="double">
                <img src="/gallery/pola_medieval.jpg"/>
                <img src="/gallery/pola_911.jpg"/>
            </div>
            {/* old images */}
            <div className="single">
                <img src="/gallery/den2.jpg"/>
            </div>
            <div className="double">
                <img src="/gallery/logohoodie.jpg"/>
                <img src="/gallery/poppy2.jpg"/>
            </div>
            <div className="single">
                <img src="/gallery/sitting.jpg"/>
            </div>
            <div className="double">
                <img src="/gallery/serve2.jpg"/>
                <img src="/gallery/serve.jpg"/>
            </div>
            <div className="double">
                <img src="/gallery/opiumhoodie.jpg"/>
                <img src="/gallery/poppy3.jpg"/>
            </div>
            <div className="double">
                <img src="/gallery/logotee.jpg" style={{filter:"hue-rotate(340deg)"}}/>
                <img src="/gallery/opiumtee.jpg" className="blur"/>
            </div>
            <div className="single_tall" style={{margin: '10vw 0'}}>
                <img src="/gallery/collage.jpg"/>
            </div>
            <Footer/>
        </div>
    );
}


/*
<div className="single_tall">
                <img src="/gallery/collage.jpg"/>
            </div>
*/