import React, { useEffect, useState } from 'react';
import Catalog from '../components/Catalog';

// components
import Footer from '../components/Footer';

// types
import { Product } from '../types/Product';

// services
import CatalogService from '../services/CatalogService';

// styles
import "../styles/Catalog.scss";

export default function CatalogPage() {
    const [products, setProducts] = useState<Array<Product>>([]);
    const [drop1, setDrop1] = useState<Array<Product>>([]);
    const [drop2, setDrop2] = useState<Array<Product>>([]);
    const catalogService = new CatalogService();

    useEffect(() => {
        catalogService.getProducts({
            _callback: r => {
                /*let d1: Array<Product> = [];
                let d2: Array<Product> = [];

                for(const p of r) {
                    if(Number(p._id) > 8) {
                        d2.push(p);
                    } else {
                        d1.push(p);
                    }
                }
                // DELETE THIS
                d2 = [{
                    "_id": "0000000000013",
                    "name": "Wrong Lane Longlseeve",
                    "brand": [
                        {
                            "id": "REMINI_00001",
                            "name": "Reminisce"
                        }
                    ],
                    "availability": [
                        {
                            "size": "S",
                            "amount": 4,
                            "prices": [
                                {
                                    "currency": "RUB",
                                    "value": 17990,
                                    "newValue": 0
                                }
                            ]
                        },
                        {
                            "size": "M",
                            "amount": 7,
                            "prices": [
                                {
                                    "currency": "RUB",
                                    "value": 17990,
                                    "newValue": 0
                                }
                            ]
                        },
                        {
                            "size": "L",
                            "amount": 9,
                            "prices": [
                                {
                                    "currency": "RUB",
                                    "value": 17990,
                                    "newValue": 0
                                }
                            ]
                        },
                        {
                            "size": "XL",
                            "amount": 8,
                            "prices": [
                                {
                                    "currency": "RUB",
                                    "value": 17990,
                                    "newValue": 0
                                }
                            ]
                        }
                    ],
                    "condition": "new",
                    "composition": [],
                    "other_data": {
                        "a": ""
                    },
                    "description": "ДВУХСЛОЙНАЯ ФУТБОЛКА С ДЛИННЫМ РУКАВОМ ИЗ ПЛОТНОГО ХЛОПКА. ДИСТРЕСС. ВИНТАЖНАЯ СТИРКА. GARMENT DYED. BOXY FIT СО СПУЩЕННЫМ ПЛЕЧОМ. СДЕЛАНО В РОССИИ.",
                    "categories": [
                        {
                            "id": "REMINI_0003",
                            "name": "longsleeve"
                        }
                    ],
                    "is_active": true,
                    "created_at": "2024-04-21T08:53:26.665050+00:00",
                    "images": [
                        {
                            "id": "",
                            "min": "/media/0013_1_tn.jpg",
                            "max": "/media/0013_1.jpg"
                        },
                        {
                            "id": "",
                            "min": "/media/0013_2_tn.jpg",
                            "max": "/media/0013_2.jpg"
                        },
                        {
                            "id": "",
                            "min": "/media/0013_3_tn.jpg",
                            "max": "/media/0013_3.jpg"
                        },
                        {
                            "id": "",
                            "min": "/media/0013_4.jpg",
                            "max": "/media/0013_4.jpg"
                        }
                    ],
                    "measurements": [],
                    "disabled_for_sale_online": false,
                    "on_remote_stock": false
                },
                {
                    "_id": "0000000000012",
                    "name": "Medieval Longsleeve",
                    "brand": [
                        {
                            "id": "REMINI_00001",
                            "name": "Reminisce"
                        }
                    ],
                    "availability": [
                        {
                            "size": "S",
                            "amount": 3,
                            "prices": [
                                {
                                    "currency": "RUB",
                                    "value": 17990,
                                    "newValue": 0
                                }
                            ]
                        },
                        {
                            "size": "M",
                            "amount": 4,
                            "prices": [
                                {
                                    "currency": "RUB",
                                    "value": 17990,
                                    "newValue": 0
                                }
                            ]
                        },
                        {
                            "size": "L",
                            "amount": 8,
                            "prices": [
                                {
                                    "currency": "RUB",
                                    "value": 17990,
                                    "newValue": 0
                                }
                            ]
                        },
                        {
                            "size": "XL",
                            "amount": 7,
                            "prices": [
                                {
                                    "currency": "RUB",
                                    "value": 17990,
                                    "newValue": 0
                                }
                            ]
                        }
                    ],
                    "condition": "new",
                    "composition": [],
                    "other_data": {
                        "a": ""
                    },
                    "description": "ДВУХСЛОЙНАЯ ФУТБОЛКА С ДЛИННЫМ РУКАВОМ ИЗ ПЛОТНОГО ХЛОПКА. ДИСТРЕСС. ВИНТАЖНАЯ СТИРКА. GARMENT DYED. BOXY FIT СО СПУЩЕННЫМ ПЛЕЧОМ. СДЕЛАНО В РОССИИ.",
                    "categories": [
                        {
                            "id": "REMINI_0003",
                            "name": "longsleeve"
                        }
                    ],
                    "is_active": true,
                    "created_at": "2024-04-21T08:53:14.208448+00:00",
                    "images": [
                        {
                            "id": "",
                            "min": "/media/0012_1_tn.jpg",
                            "max": "/media/0012_1.jpg"
                        },
                        {
                            "id": "",
                            "min": "/media/0012_2_tn.jpg",
                            "max": "/media/0012_2.jpg"
                        },
                        {
                            "id": "",
                            "min": "/media/0012_3_tn.jpg",
                            "max": "/media/0012_3.jpg"
                        },
                        {
                            "id": "",
                            "min": "/media/0012_4.jpg",
                            "max": "/media/0012_4.jpg"
                        }
                    ],
                    "measurements": [],
                    "disabled_for_sale_online": false,
                    "on_remote_stock": false
                },
                {
                    "_id": "0000000000011",
                    "name": "NA Logo Hoodie",
                    "brand": [
                        {
                            "id": "REMINI_00001",
                            "name": "Reminisce"
                        }
                    ],
                    "availability": [
                        {
                            "size": "S",
                            "amount": 2,
                            "prices": [
                                {
                                    "currency": "RUB",
                                    "value": 19990,
                                    "newValue": 0
                                }
                            ]
                        },
                        {
                            "size": "M",
                            "amount": 2,
                            "prices": [
                                {
                                    "currency": "RUB",
                                    "value": 19990,
                                    "newValue": 0
                                }
                            ]
                        },
                        {
                            "size": "L",
                            "amount": 1,
                            "prices": [
                                {
                                    "currency": "RUB",
                                    "value": 19990,
                                    "newValue": 0
                                }
                            ]
                        },
                        {
                            "size": "XL",
                            "amount": 2,
                            "prices": [
                                {
                                    "currency": "RUB",
                                    "value": 19990,
                                    "newValue": 0
                                }
                            ]
                        }
                    ],
                    "condition": "new",
                    "composition": [],
                    "other_data": {
                        "a": ""
                    },
                    "description": "ПУЛОВЕР С КАПЮШОНОМ ИЗ СВЕРХПЛОТНОГО ХЛОПКА. BOXY FIT СО СПУЩЕННЫМ ПЛЕЧОМ. ВИНТАЖНАЯ СТИРКА. ДВУХСЛОЙНЫЙ КАПЮШОН. КЕНГУРУ КАРМАН. СДЕЛАНО В РОССИИ.",
                    "categories": [
                        {
                            "id": "REMINI_0002",
                            "name": "hoodie"
                        }
                    ],
                    "is_active": true,
                    "created_at": "2024-04-21T08:33:21.875514+00:00",
                    "images": [
                        {
                            "id": "",
                            "min": "/media/0011_1_tn.jpg",
                            "max": "/media/0011_1.jpg"
                        },
                        {
                            "id": "",
                            "min": "/media/0011_2_tn.jpg",
                            "max": "/media/0011_2.jpg"
                        },
                        {
                            "id": "",
                            "min": "/media/0011_3_tn.jpg",
                            "max": "/media/0011_3.jpg"
                        },
                        {
                            "id": "",
                            "min": "/media/0011_4.jpg",
                            "max": "/media/0011_4.jpg"
                        }
                    ],
                    "measurements": [],
                    "disabled_for_sale_online": false,
                    "on_remote_stock": false
                },
                {
                    "_id": "0000000000009",
                    "name": "9/11 Hoodie",
                    "brand": [
                        {
                            "id": "REMINI_00001",
                            "name": "Reminisce"
                        }
                    ],
                    "availability": [
                        {
                            "size": "S",
                            "amount": 4,
                            "prices": [
                                {
                                    "currency": "RUB",
                                    "value": 23990,
                                    "newValue": 0
                                }
                            ]
                        },
                        {
                            "size": "M",
                            "amount": 3,
                            "prices": [
                                {
                                    "currency": "RUB",
                                    "value": 23990,
                                    "newValue": 0
                                }
                            ]
                        },
                        {
                            "size": "L",
                            "amount": 8,
                            "prices": [
                                {
                                    "currency": "RUB",
                                    "value": 23990,
                                    "newValue": 0
                                }
                            ]
                        },
                        {
                            "size": "XL",
                            "amount": 1,
                            "prices": [
                                {
                                    "currency": "RUB",
                                    "value": 23990,
                                    "newValue": 0
                                }
                            ]
                        }
                    ],
                    "condition": "new",
                    "composition": [],
                    "other_data": {
                        "a": ""
                    },
                    "description": "ПУЛОВЕР С КАПЮШОНОМ ИЗ СВЕРХПЛОТНОГО ХЛОПКА. BOXY FIT СО СПУЩЕННЫМ ПЛЕЧОМ. ВИНТАЖНАЯ СТИРКА. ДВУХСЛОЙНЫЙ КАПЮШОН. КЕНГУРУ КАРМАН. СДЕЛАНО В РОССИИ.",
                    "categories": [
                        {
                            "id": "REMINI_0002",
                            "name": "hoodie"
                        }
                    ],
                    "is_active": true,
                    "created_at": "2024-04-21T08:32:17.766637+00:00",
                    "images": [
                        {
                            "id": "",
                            "min": "/media/0009_1_tn.jpg",
                            "max": "/media/0009_1.jpg"
                        },
                        {
                            "id": "",
                            "min": "/media/0009_2_tn.jpg",
                            "max": "/media/0009_2.jpg"
                        },
                        {
                            "id": "",
                            "min": "/media/0009_3_tn.jpg",
                            "max": "/media/0009_3.jpg"
                        },
                        {
                            "id": "",
                            "min": "/media/0009_4.jpg",
                            "max": "/media/0009_4.jpg"
                        }
                    ],
                    "measurements": [],
                    "disabled_for_sale_online": false,
                    "on_remote_stock": false
                },
                {
                    "_id": "0000000000010",
                    "name": "Washed Black Hoodie",
                    "brand": [
                        {
                            "id": "REMINI_00001",
                            "name": "Reminisce"
                        }
                    ],
                    "availability": [
                        {
                            "size": "S",
                            "amount": 0,
                            "prices": [
                                {
                                    "currency": "RUB",
                                    "value": 18990,
                                    "newValue": 0
                                }
                            ]
                        },
                        {
                            "size": "M",
                            "amount": 3,
                            "prices": [
                                {
                                    "currency": "RUB",
                                    "value": 18990,
                                    "newValue": 0
                                }
                            ]
                        },
                        {
                            "size": "L",
                            "amount": 8,
                            "prices": [
                                {
                                    "currency": "RUB",
                                    "value": 18990,
                                    "newValue": 0
                                }
                            ]
                        },
                        {
                            "size": "XL",
                            "amount": 4,
                            "prices": [
                                {
                                    "currency": "RUB",
                                    "value": 18990,
                                    "newValue": 0
                                }
                            ]
                        }
                    ],
                    "condition": "new",
                    "composition": [],
                    "other_data": {
                        "a": ""
                    },
                    "description": "ПУЛОВЕР С КАПЮШОНОМ ИЗ СВЕРХПЛОТНОГО ХЛОПКА. BOXY FIT СО СПУЩЕННЫМ ПЛЕЧОМ. ВИНТАЖНАЯ СТИРКА. ДВУХСЛОЙНЫЙ КАПЮШОН. КЕНГУРУ КАРМАН. СДЕЛАНО В РОССИИ.",
                    "categories": [
                        {
                            "id": "REMINI_0002",
                            "name": "hoodie"
                        }
                    ],
                    "is_active": true,
                    "created_at": "2024-04-21T08:32:50.975922+00:00",
                    "images": [
                        {
                            "id": "",
                            "min": "/media/0010_1_tn.jpg",
                            "max": "/media/0010_1.jpg"
                        },
                        {
                            "id": "",
                            "min": "/media/0010_2_tn.jpg",
                            "max": "/media/0010_2.jpg"
                        },
                        {
                            "id": "",
                            "min": "/media/0010_3_tn.jpg",
                            "max": "/media/0010_3.jpg"
                        },
                        {
                            "id": "",
                            "min": "/media/0010_4.jpg",
                            "max": "/media/0010_4.jpg"
                        }
                    ],
                    "measurements": [],
                    "disabled_for_sale_online": false,
                    "on_remote_stock": false
                }
            ];
                // setProducts(r);
                setDrop1(d1);
                setDrop2(d2);*/
                setProducts(r);
            }
        });
    }, []);

    const [viewMode, setViewMode] = useState<"man" | "item">("man");

    return(
        <div className='CatalogPage__root'>
            <div className='catalog_wrapper'>
                {/*<p className='drop_title'>DROP II</p>*/}
                <Catalog
                    products={products}
                    viewMode={viewMode}
                />
                {/*<p className='drop_title'>DROP I</p>
                <Catalog
                    products={drop1}
                    viewMode={viewMode}
                />*/}
            </div>
            <div className='view_selector' onClick={()=>setViewMode(viewMode=="item"?"man":"item")}>
                {viewMode == "man" ? 
                <img src="/t-shirt.png" />
                :
                <img src="/man.png" />
                }
            </div>
            <Footer/>
        </div>
    );
}
