import React, { useEffect, useState } from 'react'; // @ts-ignore
import { Helmet } from 'react-helmet';

// styles
import "../styles/Product.scss";

// types
import { Measurement, Product } from '../types/Product';

// components
import Footer from '../components/Footer';

// services
import CatalogService from '../services/CatalogService';
import { CartProduct } from '../types/CartProduct';

export default function ProductPage({ cart, onAdd, onRemove }: {
    cart: Array<CartProduct>,
    onAdd: (p: CartProduct) => void,
    onRemove: (p: CartProduct) => void
}) {

    const [__render, __setRender] = useState<number>(0);
    const [productID, setProductID] = useState<string>("");
    const [product, setProduct] = useState<Product>();
    const catalogService = new CatalogService();

    const [measurement, setMeasurement] = useState<Measurement | null>(null);

    const [imgIndex, setImgIndex] = useState<number>(-1);

    useEffect(() => {
        console.log(imgIndex);
    }, [imgIndex]);

    const showNextImage = () => { // @ts-ignore
        if(imgIndex == product.images.length - 1) {
            setImgIndex(0);
        } else {
            setImgIndex(imgIndex + 1);
        }
    }

    const showPrevImage = () => { 
        if(imgIndex == 0) { // @ts-ignore
            setImgIndex(product.images.length - 1);
        } else {
            setImgIndex(imgIndex - 1);
        }
    }

    useEffect(() => {
        let patharr = window.location.pathname.split("/");
        let pid = patharr[patharr.length - 1];
        if(pid == "") {
            pid = patharr[patharr.length - 2];
        }

        setProductID(pid);
    }, []);

    useEffect(() => {
        if(productID != "") {
            catalogService.getProduct({
                id: productID,
                _callback: p => {
                    setProduct(p);
                }
            });
        }
    }, [productID]);

    const [selectedStockItemIndex, setSelectedStockItemIndex] = useState<number>(-1);

    function addToCart(i: number) { // @ts-ignore
        let __product = JSON.parse(JSON.stringify(product));
        onAdd({
            id: __product._id,
            size: __product.availability[i].size
        });
    }

    function removeFromCart(i: number) {
        let __product = JSON.parse(JSON.stringify(product));
        onRemove({
            id: __product._id,
            size: __product.availability[i].size
        });
    }

    function isInCart(i: number) {
        if(i != -1) { // @ts-ignore
            let __product = JSON.parse(JSON.stringify(product));
            let cartItem = {
                id: __product._id,
                size: __product.availability[i].size
            };
            let flag = false;
            for(const c of cart) {
                if(c.id == cartItem.id && c.size == cartItem.size) {
                    flag = true;
                }
            }
            return flag;
        } else {
            return false;
        }
    }

    useEffect(() => {

        __setRender(1-__render);
    }, [cart]);

    useEffect(() => {
        if(product?.availability != undefined) {
            if(selectedStockItemIndex != -1) {
                let sizeStr = product.availability[selectedStockItemIndex].size;
                let flag = false;
                for(const item of product.measurements) {
                    if(item.size == sizeStr) {
                        setMeasurement(item);
                        flag = true;
                    }
                }

                if(!flag) {
                    setMeasurement(null);
                }
            }
        }
    }, [selectedStockItemIndex, product]);

    if(!product) {
        return(<div/>);
    }

    return(
        <>
        <Helmet>
            <script type="text/javascript">{`
        function onAddToCart() {
            _tmr.push({ type: 'reachGoal', id: 3384321, goal: 'Cart'});
        }

        document.querySelector('#add_to_cart').onmousedown = onAddToCart;
        `}</script>
        </Helmet>
        <div className="PP__root">
            {imgIndex!=-1 &&
            <div className='overlay_gallery'>
                <div className='sel1' onClick={showPrevImage}>
                    <img src='/chevron.png' style={{transform:"rotate(180deg)"}}/>
                </div>
                <div className='sel2' onClick={showNextImage}>
                    <img src='/chevron.png'/>
                </div>
                <div className='close_button' onClick={()=>setImgIndex(-1)}>
                    <img src="/close.png"/>
                </div>
                <img className='image' src={product.images[imgIndex].max} style={imgIndex==2?{filter:"brightness(1.1)"}:{}} />
            </div>
            }

            <div className="grid">
                <div className="gallery">
                    <div className="img-wrapper img1" onClick={()=>setImgIndex(0)}>
                        <img className="img lightup" src={product.images[0].max} alt="product-image" />
                    </div>

                    <div className="img-wrapper img2" onClick={()=>setImgIndex(1)}>
                        <img className="img lightup" src={product.images[1].max} alt="product-image" />
                    </div>

                    <div className="img-wrapper img3" onClick={()=>setImgIndex(2)}>
                        <img className="img tall" src={product.images[2].max} alt="product-image" />
                    </div>
                </div>

                <div className="actions">
                    <p className='name'>{product.name}</p>

                    <div className="size_selector">
                        {product.availability.map((stockItem, index) => {
                            /*return(
                                <div onClick={() => {
                                    if(stockItem.amount > 0) {
                                        setSelectedStockItemIndex(index);
                                    }
                                }} className="size_item" style={index==selectedStockItemIndex ? {borderColor: "#000"} : {borderColor: "#fff"}}>
                                    <p className='size'>{stockItem.size}</p>
                                    {stockItem.amount == 1 && <p className='lastone'>One left</p>}
                                    {stockItem.amount > 0 ?
                                    (stockItem.prices[0].newValue == 0 ?
                                    <p className='price'>
                                        {Math.round(stockItem.prices[0].value/100)} {stockItem.prices[0].currency}
                                    </p>    
                                    :
                                    <p className='price'>
                                        <span style={{color:"#a0a0a0",textDecorationLine:'line-through'}}>{Math.round(stockItem.prices[0].value/100)}</span> {Math.round(stockItem.prices[0].newValue/100)} {stockItem.prices[0].currency}
                                    </p>
                                    )
                                    :
                                    <p className='price'>SOLD OUT</p>    
                                    }
                                </div>
                            );*/
                            return(
                                <div
                                    onClick={() => {
                                        if(stockItem.amount > 0) {
                                            setSelectedStockItemIndex(index);
                                        }
                                    }}
                                    className="size_item_small"
                                    style={index==selectedStockItemIndex ? {borderColor: "#000"} : (stockItem.amount<1?{borderColor: "#fff",backgroundColor:"#dedede"}:{borderColor: "#fff"})}
                                >
                                    <p className='size'>{stockItem.size}</p>
                                </div>
                            );
                        })}
                    </div>

                    {/*product.availability[0].prices[0].newValue == 0 ?
                    <p className='price_single'>
                        {Math.round(product.availability[0].prices[0].value/100)} {product.availability[0].prices[0].currency}
                    </p>    
                    :
                    <p className='price_single'>
                        <span style={{color:"#a0a0a0",textDecorationLine:'line-through'}}>{Math.round(product.availability[0].prices[0].value/100)}</span> {Math.round(product.availability[0].prices[0].newValue/100)} {product.availability[0].prices[0].currency}
                    </p>*/}

                    {/*isInCart(selectedStockItemIndex) ?
                    <div className={"cart_button cart_active"} onClick={()=>removeFromCart(selectedStockItemIndex)}>
                        <p className="cart_title">В КОРЗИНЕ</p>
                    </div>
                    :*/}
                    <div 
                        id="add_to_cart" 
                        className={selectedStockItemIndex==-1?"cart_button_disabled":(isInCart(selectedStockItemIndex) ? "cart_button cart_active" : "cart_button")} 
                        onClick={()=>{
                            if(isInCart(selectedStockItemIndex)) {
                                removeFromCart(selectedStockItemIndex);
                            } else {
                                if(selectedStockItemIndex != -1) {
                                    addToCart(selectedStockItemIndex);
                                }
                            }
                        }
                    }>
                        {isInCart(selectedStockItemIndex) ? 
                        <p className="cart_title">В КОРЗИНЕ</p>
                        :
                        <p className="cart_title">В КОРЗИНУ {selectedStockItemIndex > -1 && `- ${product.availability[selectedStockItemIndex].prices[0].value} Р`}</p>
                        }
                    </div>
                    

                    {product.composition.length > 0 &&
                    <>
                        <p className='composition_title'>Состав</p>
                        {product.composition.map((comp, index) => {
                            return(
                                <div className="composition_container">
                                    <p className='alias'>{comp.alias}</p>
                                    <p className='value'>{comp.value}</p>
                                </div>
                            );
                        })}
                    </>
                    }

                    {product.description != "" &&
                    <>
                        <p className='composition_title'>Описание</p>
                        <div className="desc_container">
                            <p className='desc'>{product.description}</p>
                        </div>
                    </>
                    }

                    {measurement != null &&
                    <>
                        <p className='composition_title'>Замеры</p>
                        {measurement.values.map((item, index) => {
                            return(
                                <div className="composition_container">
                                    <p className='alias'>{item.title}</p>
                                    <p className='value'>{item.value}</p>
                                </div>
                            );
                        })}
                    </>
                    }
                </div>
            </div>
            <Footer/>
        </div>
        </>
    );
}