import React from "react";
import "./TextInput.scss";

export default function TextInput({
    title,
    value="",
    onChangeText=()=>{},
    placeholder="",
    style={},
    errorMessage="",
    disabled=false
}: {
    title: string,
    value?: string,
    onChangeText?: (text: string)=>void,
    placeholder?: string,
    style?: any,
    errorMessage?: string,
    disabled?: boolean
}) {
    return(
        <div className="TextInput__container" style={style}>
            <p className="ti_title">{title}</p>
            <input
                value={value}
                placeholder={placeholder}
                onChange={e=>onChangeText(e.target.value)}
                disabled={disabled}
                style={disabled ? {backgroundColor:"#f0f0f0"}:{}}
            />
            {errorMessage!="" && <p className="error">{errorMessage}</p>}
        </div>
    );
}
