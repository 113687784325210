import React from 'react';

// styles
import "./CollectionBanner.scss";
import { Link } from 'react-router-dom';

export default function CollectionBanner({
    name,
    image,
    id,
    link
}: {
    name: string,
    image: string,
    id: string,
    link: string
}) {
    return( // link: `collection/${id}`
        <Link to={link} className="Col__a"><div className="Col_banner__root">
            <div className='hovering' style={{width:"100%",display:'flex',flexDirection:'column',justifyContent:'flex-start',alignItems:'center'}}>
                <p className="name">{name}</p>
                <div className='line'/>
            </div>
            <div className="bg">
                <img className="bg_img" src={image} alt="banner-background" />
            </div>
        </div></Link>
    );
}