import { useEffect, useState } from "react";
import "../styles/Game.scss";

export default function GamePage() {
    const [blocked, setBlocked] = useState<boolean>(false);
    useEffect(() => {
        if(window.screen.width < 800) {
            setBlocked(true);
        }
    }, []);

    if(blocked) {
        return(
            <div className="GamePage__root">
                <p>Для получения эмоций подключитесь с ПК</p>
            </div>
        );
    }

    return(
        <div className="GamePage__root">
            <iframe src="https://www.crazygames.com/embed/sandbox-city---cars-zombies-ragdolls" width="960" height="640" allow="gamepad *;"></iframe>
        </div>
    );
}
