import React, { useEffect, useState } from "react";
import { Order } from "../types/Order"; // @ts-ignore
import OrderService from "../services/OrderService.ts";
import "../styles/OrderTracking.scss";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";

const orderService = new OrderService();

export default function OrderTrackingPage() {
    const [order, setOrder] = useState<Order | null>(null);

    useEffect(() => {
        let patharr = window.location.pathname.split("/");
        let orderid = patharr[patharr.length-1];
    
        orderService.getOrder({
            id: orderid,
            _callback: (o: Order) => {
                setOrder(o);
            }
        });
    }, []);

    if(order == null) {return <div/>}

    return(
        <div className="Track__root">
            <div className="wrapper">
                <p className="title">Заказ #{order._id}</p>
                <p className="desc">На этой странице вы можете следить за обработкой своего заказа.</p>
                <div className="table">
                    <p className="g_title">Сумма заказа</p>
                    <p className="g_value">{order.total} {order.currency}</p>

                    <p className="g_title">Статус</p>
                    <p className="g_value">{translateStatus(order.status)}, {order.is_paid ? "оплачен" : "не оплачен"}</p>

                    <p className="g_title">Дата размещения</p>
                    <p className="g_value">{formatDate(order.created_at)}</p>

                    <p className="g_title">Тип доставки</p>
                    <p className="g_value">{translateDeliveryType(order.delivery_type)}</p>

                    <p className="g_title">Стоимость доставки</p>
                    <p className="g_value">{order.delivery_cost} {order.currency}</p>

                    {order.delivery_address != "" &&
                    <>
                    <p className="g_title">Адрес доставки</p>
                    <p className="g_value">{order.delivery_address}</p>
                    </>
                    }

                    <p className="g_title">Товары в заказе</p>
                    <p className="g_value">{order.products.map((p, i) => {
                        return(<span>{p.name}, {p.availability[0].size}<br/></span>);
                    })}</p>

                    {(!order.is_paid && order.delivery_type != "pickup" && order.expect_payment == false && order.status != "cancelled") &&
                    <Link to={`/checkout/${order._id}`} className="pay_button">
                        <p>Перейти к оплате</p>
                    </Link>
                    }

                    {order.expect_payment &&
                    <p className="g_value" style={{marginTop:"24px"}}>Оплата в процессе. Обычно это занимает меньше минуты.</p>
                    }
                </div>
            </div>

            <Footer/>
        </div>
    );
}

function translateStatus(s: string) {
    switch(s) {
        case "opened":
            return "Открыт";
        case "handled":
            return "Обработан";
        case "on_delivery":
            return "Доставляется";
        case "completed":
            return "Выполнен";
        case "cancelled":
            return "Отменен";
        default:
            return s;
    }
}

function translateDeliveryType(t: string) {
    switch(t) {
        case "pickup":
            return "Самовывоз";
        case "courier_moscow":
            return "Курьером по Москве";
        case "courier":
            return "Курьером по России";
        default:
            return t;
    }
}

function formatDate(s: string) {
    return `${s.slice(8, 10)}.${s.slice(5, 7)}.${s.slice(0, 4)} в ${s.slice(11, 13)}:${s.slice(14, 16)}`;
}
